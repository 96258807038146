.wallet-overview {
    background: #ececec;
    padding: 30px;
}

.balance-wrap .card {
    margin-right: 10px;
}

.balance-wrap .ant-statistic-title {
    font-weight: 500;
    color: #1a1a1a;
}


.custom_margin{

    margin-right:0px !important;
}
@media (max-width: 670px) {
    .custom_margin {
        margin-right: 36px !important;
    }
}
@media (max-width: 992px) {
    .custom_margin {
        margin-right: 30px !important;
    }
}

.custom_margin_left{

    margin-right:0px !important;
}
@media (max-width: 670px) {
    .custom_margin_left {
        margin-right: 36px !important;
    }
}
@media (max-width: 992px) {
    .custom_margin_left {
        margin-right: 30px !important;
    }
}