/* Transition */
.buy-agent-track-steps {
  padding: 0;
  margin-top: 27px;
  display: flex !important;
  list-style: none;
  flex-direction: column;
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps {
        transition: 0.5s;
        margin-top: 0;
    }
}

@media (min-width: 576px) {
  .buy-agent-track-steps {
    align-items: center !important;
    flex-direction: row;
    justify-content: space-between !important;
  }
}

.buy-agent-track-steps .item {
    position: relative;
    min-height: 90px;
    width: 100%;
    text-align: center;
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item {
        transition: 0.5s;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
    }
}

.buy-agent-track-steps .item span {
    display: block;
    font-weight: 500;
    color: #242a18;
    font-size: 12px;
}

@media all and (max-width: 991px) {
    .buy-agent-track-steps .item span {
        line-height: 1.2;
        font-size: 11px;
    }
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item span {
        transition: 0.5s;
        left: 60px;
        position: absolute;
        bottom: 30px;
    }
}

.buy-agent-track-steps .item .step {
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    border: 2px solid transparent;
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    z-index: 6;
}

.buy-agent-track-steps .item .step .checked-ico {
    display: none;
}

.buy-agent-track-steps .item .step.checked .checked-ico {
    display: block;
}

.buy-agent-track-steps .item .step.checked .uncheck-ico {
    display: none;
}

@media all and (max-width: 991px) {
    .buy-agent-track-steps .item .step {
        top: 45px;
    }
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item .step {
        transition: 0.5s;
        width: 32px;
        height: 32px;
        top: 38px;
        left: 25px;
    }
}

.buy-agent-track-steps .item:after {
    content: "";
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #ebebeb;
    z-index: 5;
}

@media all and (max-width: 991px) {
    .buy-agent-track-steps .item:after {
        bottom: 22px;
    }
}

@media all and (max-width: 480px) {
    .buy-agent-track-steps .item:after {
        bottom: 34px;
        height: 3px;
    }
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item:after {
        transition: 0.5s;
        left: 25px;
        position: absolute;
        width: 3px;
        height: 90px;
        bottom: -15px;
    }
}

.buy-agent-track-steps .item.active:after {
    background-color: #62842c;
}

.buy-agent-track-steps .item:first-child {
    text-align: left;
}

.buy-agent-track-steps .item:first-child:after {
    left: 10px;
    background-color: #62842c;
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item:first-child:after {
        transition: 0.5s;
        left: 25px;
        height: 60px;
    }
}

.buy-agent-track-steps .item:first-child .step {
    left: 10px;
    transform: translateX(0%);
    background-color: #62842c;
    border-color: #62842c;
}

.buy-agent-track-steps .item:nth-child(2).active:after {
    background-color: #4d9cfe;
}

.buy-agent-track-steps .item:nth-child(2) .step {
    background-color: #d9fff0;
    border-color: #4d9cfe;
}

.buy-agent-track-steps .item:nth-child(2) .step.checked {
    background-color: #4d9cfe;
}

.buy-agent-track-steps .item:nth-child(3).active:after {
    background-color: #5946af;
}

.buy-agent-track-steps .item:nth-child(3) .step {
    background-color: #e9e4ff;
    border-color: #5946af;
}

.buy-agent-track-steps .item:nth-child(3) .step.checked {
    background-color: #5946af;
}

.buy-agent-track-steps .item:nth-child(4).active:after {
    background-color: #33c58c;
}

.buy-agent-track-steps .item:nth-child(4) .step {
    background-color: #e9e4ff;
    border-color: #33c58c;
}

.buy-agent-track-steps .item:nth-child(4) .step.checked {
    background-color: #33c58c;
}

.buy-agent-track-steps .item:nth-child(5).active:after {
    background-color: #5946af;
}

.buy-agent-track-steps .item:nth-child(5) .step {
    background-color: #e9e4ff;
    border-color: #5946af;
}

.buy-agent-track-steps .item:nth-child(5) .step.checked {
    background-color: #5946af;
}

.buy-agent-track-steps .item:last-child {
    text-align: right;
}

.buy-agent-track-steps .item:last-child:after {
    right: 15px;
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item:last-child:after {
        transition: 0.5s;
        height: 60px;
        top: 0;
    }
}

.buy-agent-track-steps .item:last-child.active:after {
    background-color: #f6921e;
}

.buy-agent-track-steps .item:last-child .step {
    left: calc(100% - 15px);
    transform: translateX(-100%);
    background-color: #ffe6c8;
    border-color: #f6921e;
}

@media all and (max-width: 575px) {
    .buy-agent-track-steps .item:last-child .step {
        transition: 0.5s;
        left: 10px;
        transform: translateX(0);
    }
}

.process-status {
    border-color: rgba(254, 152, 1, 0.35) !important;
    background-color: rgba(254, 152, 1, 0.08);
    font-weight: 500;
    color: #fe9801;
}

.track-summary .table {
    min-width: 720px;
    margin: 0;
}

.track-summary .table td {
    border: none;
    padding: 0.5rem;
}

.track-summary .table td:first-child, .track-summary .table td:nth-child(2) {
    min-width: 125px;
}

.track-summary .track-message .product-images .item {
    width: 80px;
    height: 80px;
    padding: 2px;
    cursor: pointer;
    border-color: #d8d8d8 !important;
}

.track-summary .track-message a {
    color: #88b34c;
    transition: 300ms ease-in-out;
    text-decoration: none;
}

.track-summary .track-message a:hover {
    color: #fe9801;
}
