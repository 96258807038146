.ssl-button {
    background-color: #3498DB;
    height: 2.5rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 500;
    overflow: hidden;
    -webkit-transition: 300ms ease-in-out opacity;
    transition: 300ms ease-in-out opacity;
    cursor: pointer;
    color: #fff !important;
    margin: auto !important;
    padding-right: 1rem !important;
    padding-left: 0 !important;
    align-items: center !important;
    display: flex !important;
    border-radius: 0.25rem !important;
    border: 0 !important;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: button;
}

.ssl-button:hover {
    background-color: #3498DB;
    height: 2.5rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 500;
    overflow: hidden;
    -webkit-transition: 300ms ease-in-out opacity;
    transition: 300ms ease-in-out opacity;
    cursor: pointer;
    color: #fff !important;
    margin: auto !important;
    padding-right: 1rem !important;
    padding-left: 0 !important;
    align-items: center !important;
    display: flex !important;
    border-radius: 0.25rem !important;
    border: 0 !important;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: button;
}

.ssl-button-ico {
    background-color: #2B5DA8;
    padding: 0 1.25rem;
    height: inherit;
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
    position: relative;
    left: -0.625rem;
    margin-right: 0.5rem !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}