.place-order-first-section-header {
  text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.place-order-first-section-label {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.place-order-first-section-select .ant-select-selector {
  background: transparent !important;
}

.place-order-first-section-select {
  height: 35px;
  width: 300px;
}

@media only screen and (min-width: 538px) and (max-width: 650px) {
  .place-order-first-section-input {
    display: "flex";
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .place-order-first-section-select {
    width: 200px;
  }
}

.place-order-first-section-select .ant-select-selection-item {
  color: #fff !important;
}

.place-order-first-section-select .ant-select-arrow {
  color: #fff !important;
}

.place-order-address-section-header {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.place-order-address-text {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.016px;
}

.order-place-table-text {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.order-place-modal-wrapper {
  background: #fff;
  padding: 48px;
}

@media (max-width: 500px) {
  .order-place-modal-wrapper {
    padding: 8px;
  }
}

.order-place-modal-header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.ant-input-search-button {
  background-color: #1d3148;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.order-place-bottom-cta {
  color: black;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 33px;
}

.custom-quantity-input::-webkit-inner-spin-button,
.custom-quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
