.gateway-selection {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    max-height: 4.375rem;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 1.5rem;
    border: 1px solid;
    border-color: #D8D8D8 !important;
    color: #242A18;
    background-color: #ffffff;
    -webkit-transition: 300ms ease-in-out border-color, background-color;
    transition: 300ms ease-in-out border-color, background-color;
    border-radius: 0.25rem !important;
}

.gateway-selection img {
    height: 1.875rem;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.gateway-selection span {
    padding: 0px 0px 0px 8px;
}