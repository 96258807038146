.product-title-wrapper {
    text-align: left;
    margin-left: 25px;
}

.product-title-wrapper .title {

}

.product-title-wrapper .link {
    text-decoration: none;
}

.product-title-wrapper span {
    font-weight: bold;
}

.product-title-wrapper div {
    margin-bottom: 10px;
}

.product-metas .meta-item {
    text-align: left;
    padding-left: 20px;
}

.product-metas .meta-item span, .product-extras .extra-item span {
    font-weight: bold;
    text-align: left;
}

.product-extras .extra-item {
    text-align: left;
    padding-left: 20px;
}

.customer-addresses {

}

.address-section .address-item span {
    font-weight: bold;
    margin-right: 10px;
    width: 200px;
}

.customer-addresses .selected {
    background: darkolivegreen;
    color: #ffffff;
    border-radius: 10px;
}

.address-section {
    margin-top: 10px !important;
}

.order-form-wrapper .address-view-wrapper {
    margin-left: auto;
}

.order-form-wrapper .address-view-wrapper .address-add-button {
    margin-left: auto;
}

.single-address {
    width: 250px;
    margin-right: 20px;
}

.single-address .heading {
    font-weight: bold;
    padding-bottom: 15px;
}

.products-list-wrapper {
    margin-top: 20px;
}