/* Border Color */


/* Transition */

.invoice-wrap {
    background-color: #fff;
    padding: 20px 20px;
}

@media all and (max-width: 575px) {
    .invoice-wrap {
        transition: 0.5s;
        padding: 0.8rem;
    }
}

.invoice-wrap__gretting {
    font-size: 1.375rem;
    line-height: 1.8125rem;
    color: #242a18;
    font-weight: 500;
    margin-top: 3rem;
}

.invc {
    color: #5d6154;
}

.invc .print-btn button {
    background-color: transparent;
    font-size: 1.25rem;
    color: #878a81;
    transition: 300ms ease-in-out color;
    border: none;
}

.invc .print-btn button:hover {
    color: #88b34c;
}

.invc__date-num span {
    font-size: 1.25rem;
    font-weight: bold;
    color: #242a18;
    line-height: 2.125rem;
    margin-top: 0.5rem;
    display: inline-block;
}

.invc__logo img {
    max-height: 4rem;
}

/*.invc__date-num,*/
/*.invc__logo {*/
/*  display: none;*/
/*}*/

.invc__billing-address,
.invc__shipping-address {
    margin-top: 0.5rem;
}

.invc__billing-address span,
.invc__shipping-address span {
    color: #242a18;
    font-size: 1.25rem;
    display: inline-block;
    font-weight: 600;
    margin: 0.625rem 0 12px;
}

.buy-ship-ivoices-tables .table {
    min-width: 780px;
}

.buy-ship-ivoices-tables .table .product_title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buy-ship-ivoices-tables .table .product_charges {
    font-weight: bold;
    color: #88b34c;
}

.buy-ship-ivoices-tables .table .product_charges_amount {
    font-weight: bold;
    color: #88b34c;
}

.buy-ship-ivoices-tables .table thead th,
.buy-ship-ivoices-tables .table tbody th,
.buy-ship-ivoices-tables .table thead td,
.buy-ship-ivoices-tables .table tbody td {
    border-color: #d8d8d8 !important;
}

.buy-ship-ivoices-tables .table thead th:first-child .product_sl,
.buy-ship-ivoices-tables .table tbody th:first-child .product_sl,
.buy-ship-ivoices-tables .table thead td:first-child .product_sl,
.buy-ship-ivoices-tables .table tbody td:first-child .product_sl {
    display: none;
}

.buy-ship-ivoices-tables .table thead th:nth-child(2),
.buy-ship-ivoices-tables .table tbody th:nth-child(2),
.buy-ship-ivoices-tables .table thead td:nth-child(2),
.buy-ship-ivoices-tables .table tbody td:nth-child(2) {
    width: 46%;
}

.buy-ship-ivoices-tables .table thead th:nth-child(3),
.buy-ship-ivoices-tables .table tbody th:nth-child(3),
.buy-ship-ivoices-tables .table thead td:nth-child(3),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) {
    width: 45%;
}

.buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(2),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(2),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(2),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(2),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(2),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(2),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(2),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(2),
.buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(2),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(2),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(2),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(2),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(2),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(2),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(2),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(2) {
    width: 15%;
}

.buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(3),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(3),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(3),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(3),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(3),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(3),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(3),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(3),
.buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(3),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(3),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(3),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(3),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(3),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(3),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(3),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(3) {
    width: 15%;
    text-align: right;
}

.buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(4),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(4),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(4),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(4),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(4),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(4),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(4),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(4),
.buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(4),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(4),
.buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(4),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(4),
.buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(4),
.buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(4),
.buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(4),
.buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(4) {
    width: 5%;
    text-align: right;
}

.buy-ship-ivoices-tables .table tr td,
.buy-ship-ivoices-tables .table th {
    border: none;
    padding: 0.55rem;
}

.buy-ship-ivoices-tables .table tr.inv-product-header {
    background-color: #f8f8f8;
}

.buy-ship-ivoices-tables .table tr.inv-product-header th {
    color: #5d6154;
    font-weight: normal;
}

.buy-ship-ivoices-tables .table tbody .inv-product-item {
    border-bottom: 1px solid #d8d8d8 !important;
}

.buy-ship-ivoices-tables .table tbody .inv-product-item:nth-child(odd) {
    background-color: rgba(254, 152, 1, 0.03);
}

.buy-ship-ivoices-tables .table tbody .inv-product-item:nth-child(even) {
    background-color: rgba(136, 179, 76, 0.03);
}

.buy-ship-ivoices-tables .table tfoot .total-value {
    background-color: #88b34c;
}

.buy-ship-ivoices-tables .table tfoot .total-value td {
    color: #fff;
    font-weight: 600;
}

.buy-ship-ivoices-tables tr.inv-product-item .table,
.buy-ship-ivoices-tables tr.inv-product-header .table,
.buy-ship-ivoices-tables tfoot .table {
    margin: -0.5rem -0.5rem;
}

.buy-ship-ivoices-tables tr.inv-product-item .table tr td,
.buy-ship-ivoices-tables tr.inv-product-header .table tr td,
.buy-ship-ivoices-tables tfoot .table tr td,
.buy-ship-ivoices-tables tr.inv-product-item .table th,
.buy-ship-ivoices-tables tr.inv-product-header .table th,
.buy-ship-ivoices-tables tfoot .table th {
    width: 15%;
}

.buy-ship-ivoices-tables tr.inv-product-item .table tr td:first-child,
.buy-ship-ivoices-tables tr.inv-product-header .table tr td:first-child,
.buy-ship-ivoices-tables tfoot .table tr td:first-child,
.buy-ship-ivoices-tables tr.inv-product-item .table th:first-child,
.buy-ship-ivoices-tables tr.inv-product-header .table th:first-child,
.buy-ship-ivoices-tables tfoot .table th:first-child {
    width: 25%;
}

@media (max-width: 991px) {
    .buy-ship-ivoices-tables tr.inv-product-item .table tr td:first-child,
    .buy-ship-ivoices-tables tr.inv-product-header .table tr td:first-child,
    .buy-ship-ivoices-tables tfoot .table tr td:first-child,
    .buy-ship-ivoices-tables tr.inv-product-item .table th:first-child,
    .buy-ship-ivoices-tables tr.inv-product-header .table th:first-child,
    .buy-ship-ivoices-tables tfoot .table th:first-child {
        width: 34%;
    }
}

.transactions-table {
    margin-top: 2.5rem;
}

.transactions-table__title {
    font-size: 1.375rem;
    line-height: 1.8125rem;
    color: #242a18;
    font-weight: 600;
    margin-bottom: 1.25rem;
}

.transactions-table.buy-ship-ivoices-tables thead tr {
    background-color: #f8f8f8;
}

.transactions-table.buy-ship-ivoices-tables tbody tr:not(:last-child) {
    border-bottom: 1px solid #d8d8d8 !important;
}

.transactions-table.buy-ship-ivoices-tables tbody tr:nth-child(odd) {
    background-color: rgba(254, 152, 1, 0.03);
}

.transactions-table.buy-ship-ivoices-tables tbody tr:nth-child(even) {
    background-color: rgba(136, 179, 76, 0.03);
}

.transactions-table.buy-ship-ivoices-tables tbody tr td,
.transactions-table.buy-ship-ivoices-tables thead tr td,
.transactions-table.buy-ship-ivoices-tables tbody th,
.transactions-table.buy-ship-ivoices-tables thead th {
    font-weight: normal;
    color: #5d6154;
    vertical-align: middle;
    border: none;
}

@media print {
    @page {
        size: portrait;
    }

    .header-wrap,
    .sidebar-wrap {
        display: none;
    }

    .back-wrap {
        display: none;
    }

    .invoice {
        padding: 0;
        border-top: 0 !important;
        width: 100%;
    }

    .invoice-header {
        display: none !important;
    }

    .invoice-wrap {
        padding: 0;
        border: none;
    }

    .invc__date-num {
        display: block;
    }

    .invc__logo {
        display: block;
    }

    .invc .print-btn {
        display: none;
    }

    .invc__billing-address,
    .invc__shipping-address {
        margin-top: 2.5rem;
    }

    .invoice-pagination {
        display: none !important;
    }

    .footer-wrap {
        display: none;
    }

    .buy-ship-ivoices-tables .table {
        min-width: 780px;
    }

    .buy-ship-ivoices-tables .table thead th,
    .buy-ship-ivoices-tables .table tbody th,
    .buy-ship-ivoices-tables .table thead td,
    .buy-ship-ivoices-tables .table tbody td {
        border-color: #d8d8d8 !important;
    }

    .buy-ship-ivoices-tables .table thead th:first-child,
    .buy-ship-ivoices-tables .table tbody th:first-child,
    .buy-ship-ivoices-tables .table thead td:first-child,
    .buy-ship-ivoices-tables .table tbody td:first-child {
        width: 10%;
    }

    .buy-ship-ivoices-tables .table thead th:first-child .product_sl,
    .buy-ship-ivoices-tables .table tbody th:first-child .product_sl,
    .buy-ship-ivoices-tables .table thead td:first-child .product_sl,
    .buy-ship-ivoices-tables .table tbody td:first-child .product_sl {
        display: none;
        font-weight: bold;
        margin-left: 10%;
    }

    .buy-ship-ivoices-tables .table thead th:nth-child(2),
    .buy-ship-ivoices-tables .table tbody th:nth-child(2),
    .buy-ship-ivoices-tables .table thead td:nth-child(2),
    .buy-ship-ivoices-tables .table tbody td:nth-child(2) {
        width: 45%;
    }

    .buy-ship-ivoices-tables .table thead th:nth-child(3),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3),
    .buy-ship-ivoices-tables .table thead td:nth-child(3),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) {
        width: 45%;
    }

    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(2),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(2),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(2),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(2),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(2),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(2),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(2),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(2),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(2),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(2),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(2),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(2),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(2),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(2),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(2),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(2) {
        width: 5%;
    }

    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(3),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(3),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(3),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(3),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(3),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(3),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(3),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(3),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(3),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(3),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(3),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(3),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(3),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(3),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(3),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(3) {
        width: 5%;
    }

    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead th:nth-child(4),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead th:nth-child(4),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead th:nth-child(4),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead th:nth-child(4),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody th:nth-child(4),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody th:nth-child(4),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody th:nth-child(4),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody th:nth-child(4),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) thead td:nth-child(4),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) thead td:nth-child(4),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) thead td:nth-child(4),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) thead td:nth-child(4),
    .buy-ship-ivoices-tables .table thead th:nth-child(3) tbody td:nth-child(4),
    .buy-ship-ivoices-tables .table tbody th:nth-child(3) tbody td:nth-child(4),
    .buy-ship-ivoices-tables .table thead td:nth-child(3) tbody td:nth-child(4),
    .buy-ship-ivoices-tables .table tbody td:nth-child(3) tbody td:nth-child(4) {
        width: 5%;
    }
}