.expected-time {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    display: flex !important;
}

.expected-time .days {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.expected-time .info {
    color: #5d6154;
    margin-left: auto !important;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    align-items: center !important;
    display: flex !important;
}

.expected-time .info span {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    font-size: .9375rem;
    line-height: 1.25rem;
    border: 1px solid #5b5b5b;
    text-align: center;
    margin-right: 0.625rem;
    color: red;
}

.moveon-address .address-item span {
    font-weight: bold;
    margin-right: 10px;
    width: 200px;
}

.moveon-address {
    background: darkolivegreen;
    color: #ffffff;
}

.address-card {
    border-radius: 10px;
}