.nagad-button {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 500;
    overflow: hidden;
    transition: opacity .3s ease-in-out;
    cursor: pointer;
    color: #fff !important;
    margin: auto !important;
    padding-right: 1rem !important;
    padding-left: 0 !important;
    align-items: center !important;
    display: flex !important;
    border-radius: 0.25rem !important;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: button;
    background-color: #f7f5f2;
    height: 68px;
    width: 135px;
    border: 1px solid #c74b50 !important;
}