.product-details-wrapper {
  display: flex;
  /* flex-wrap: wrap; */
}

@media (max-width: 1400px) {
  .product-details-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 1400px) {
  .product-list-card-container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.product-list-header-text-wrapper {
  padding: 24px 100px 0px;
  display: flex;
  justify-content: start;
  white-space: nowrap;
}

@media (max-width: 550px) {
  .product-list-header-text-wrapper {
    display: flex;
    justify-content: center;
    padding: 24px 50px 0px;
  }
}

.product-list-filters {
  display: flex;
  padding: 32px 32px 0px 32px;
}

@media (max-width: 1200px) {
  .product-list-filters-first {
    order: 1;
  }
  .product-list-filters-last {
    order: 2;
  }
  .product-list-filters-second {
    order: 3;
  }
}

.product-list-filter-text {
  color: #111827;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.product-list-input {
  width: 100%;
  height: 40px;
  /*border: 1px solid #e5e7eb;*/
  border-radius: 8px;
  text-indent: 110px;
}

.product-list-card-image {
  height: 250px;
  object-fit: cover;
}

.product-list-delivery-wrapper {
  width: 133px;
  height: 32px;
  background: rgba(17, 24, 39, 0.3);
  position: absolute;
  top: 10px;
  left: -1px;
  clip-path: polygon(0% 0%, 100% 0%, 84.5% 100%, 0% 100%);
}

.product-list-delivery-icon {
  position: absolute;
  top: 7px;
  left: 10px;
  color: white;
}

.product-list-delivery-text {
  position: absolute;
  top: 8px;
  left: 35px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.product-list-card-country {
  width: 24.963px;
  height: 23.111px;
  position: absolute;
  top: 16px;
  right: 10px;
  border-radius: 10px;
}

.product-list-card-body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-content: space-evenly;
  margin-top: 4px;
}

.product-list-card-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: wrap;
}

.product-list-card-price {
  padding-top: 2px;
  color: #ff7f76;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-list-card-shipping-wrapper {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.product-list-card-shipping {
  padding-left: 2px;
  color: #121212;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-list-card-sold {
  padding-top: 7px;
  color: #121212;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-list-card-profit-text-wrapper {
  margin-top: 8px;
}

.product-list-card-profit-text {
  color: #049c4f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* white-space: wrap; */
}

.product-list-card-cta-wrapper {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (max-width: 1400px){
  .product-list-card-cta-wrapper {
    display: flex;
    /*justify-content: space-between;*/
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.product-card-btn-text {
  /*color: var(--Body-text, #4d4d4d);*/
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.product-details-title {
  color: #121212;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  white-space: wrap;
  position: relative;
}

.product-details-product-section {
  padding: 24px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .product-details-product-section {
    padding: 24px 0px;
  }
}

.product-details-domestic-shipping {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.product-details-wholesale-wrapper {
  padding-top: 21px;
  width: 100%;
  display: flex;
}

.product-load-more {
  color: #f37b78;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.product-load-more:hover {
  color: #f16b68;
}

.product-details-domestic-shipping-amount {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-details-color-title {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.product-details-size-title {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.product-details-top-text1 {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
}

.product-details-top-text2 {
  color: #9ca3af;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  padding-left: 32px;
  white-space: nowrap;
}

.product-details-total-review {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
  padding-right: 6px;
  padding-top: 2px;
}

.title-container {
  flex-grow: 1;
}

.title-container > h1 {
  margin: 0;
}

.icon-container {
  width: 14px;
  height: 14px;
}

.product-details-total-sale {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-left: 1px solid #cacaca;
  padding-left: 6px;
}

.product-details-clipboard-text {
  margin-left: 5px;
  color: #4d4d4d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.darken-on-hover {
  border-radius: 8px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.darken-on-hover:hover {
  opacity: 0.5;
}

.product-details-item-link {
  color: #1d9bf0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.product-details-price-wrapper {
  border: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  height: 100px;
  border-radius: 8px;
  white-space: wrap;
}

.product-details-price {
  color: #121212;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-size: normal;
  font-weight: 700;
  line-height: normal;
}

.product-details-price-quantity {
  color: #4d4d4d;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
}

.product-details-size-wrapper:hover {
  border: 1px solid #f37b78;
}

.product-details-size-text {
  color: rgba(29, 49, 72, 0.7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 16px;
}

.product-details-card-title {
  color: #121212;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.016px;
}

.product-details-card-description {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.014px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.product-list-card-price-subtotal {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.product-details-card-price-wrapper {
  display: flex;
  justify-content: space-between;
}

.product-details-card-price-header {
  color: #4d4d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .product-details-card-global-shipping-modal-wrapper {
    margin-left: 10px;
    margin-right: 0px;
  }
}

.product-details-card-price {
  color: #4d4d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.product-details-card-editicon {
  margin-left: 5px;
  cursor: pointer;
}

.product-details-card-editicon:hover {
  color: blue;
}

.product-details-card-subtotal-price-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.ant-tabs-tab-btn {
  font-size: 16px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
}

.product-specification-tab-btn {
  font-size: 16px;
}

.ant-tabs-tab-btn:hover {
  color: #000 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  color: "#FFF";
  border-bottom: 2px solid #ff7f76 !important;
  z-index: 2;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
}

.product-specification-container {
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 24px;
}

@media (max-width: 550px) {
  .specification-container {
    width: 100%;
  }
}

.product-specification-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding-left: 32px;
  white-space: wrap;
}

@media (max-width: 550px) {
  .product-specification-text {
    font-size: 16px;
    padding-left: 22px;
  }
}

.product-modal-label {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.product-details-modal-checkbox {
  display: flex;
  align-items: center;
  padding: 30px 16px;
}

.product-details-modal-text1 {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 16px;
}

.product-details-modal-text2 {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 16px;
  margin-top: 8px;
  display: flex;
}

.product-details-modal-text3 {
  color: #4d4d4d;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-details-modal-slot-header {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.product-details-modal-slot-cell {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-details-modal-bottom-text {
  color: #4d4d4d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 16px;
}

.ant-form-item .ant-form-item-control {
  margin-top: 6px;
}

.ant-form-item .ant-form-item-label > label {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.ant-form-item .ant-form-item-label > label::after {
  content: "";
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-collapse {
  background: #fff;
  border: none;
}

.product-list-filter-header {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--Line, #dedede);
}

.ant-collapse-header-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-left: -15px;
}

.product-list-filter-cta {
  padding-top: 20px;
  border-radius: 5px;
  background: #f37b78;
  display: flex;
  width: 196px;
  padding: 14px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.product-list-filter-clear {
  color: #ff4c4c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-tooltip .ant-tooltip-inner {
  padding: 0px !important;
}

.ant-select-selection-item {
  color: #111827;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.product-save-success {
  padding: 0px 16px;
  color: #1bbe8d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
