.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.mb-30 {
  margin-bottom: 30px;
}

.custom-submenu > div > svg {
  margin-right: 16px;
}

.custom-submenu > ul > li > span > a > span {
  margin-left: 45px;
}
.brand a {
  background-color: #ffffff !important;
  border-left: none !important;
}
.brand-logo {
  height: 40px;
  margin-bottom: 10px;
}
.primary-color {
  color: #f37b78;
}
.secondary-color {
  color: #787e87;
}

.custom-label-color .ant-form-item-label label {
  color: #787e87;
}
.item-align-center {
  align-items: center;
}
.login-card {
  padding: 60px 35px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  max-width: 430px;
}

.login-card-width {
  padding: 60px 35px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  max-width: 500px;
}
.ant-form-item-control-input-content input {
  border-radius: 5px;
}
@media (max-width: 767px) {
  .mobile-left-margin {
    margin-left: 0px !important;
  }
}

.tabs-header {
  display: flex;
}
.tabs-header-title {
  padding-left: 5px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-pagination .ant-pagination-options{
  display: none;
}