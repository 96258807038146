.product-info-wrapper{
    margin-left: 20px
}

.product-info-wrapper .cost_breakdown_wrap .cost_breakdown{
    margin-left: 20px;
}

.charge-list{
    margin-left: 10px;
}