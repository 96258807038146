.saved-product-header {
  color: #9ca3af;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.saved-product-input-label {
  color: #111827;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.saved-product-input {
  margin-top: 12px;
}
.saved-product-cta1 {
  background: #f37b78;
  font-family: Inter;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 100px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border-style: none;
}
.saved-product-cta2 {
  background: #e5e7eb;
  font-family: Inter;
  color: #1d3148;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 100px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border-style: none;
}
.saved-product-table-cta {
  color: rgba(17, 24, 39, 0.5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
}
.saved-product-table-action-btn1 {
  color: #1d9bf0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
.saved-product-table-action-btn2 {
  color: #03c68b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
.saved-product-table-action-btn3 {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: rgba(229, 231, 235, 0.5);
  height: 50px;
  color: #111827;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.saved-product-table-row-text {
  max-width: 250px;
  white-space: wrap;
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}


