/* Border Color */
/* Transition */
.product-wrap-inner {
    background-color: #fff;
    padding: 0 1.875rem 1.5625rem 1.875rem;
}

@media all and (max-width: 575px) {
    .product-wrap-inner {
        transition: 0.5s;
        padding: 0.6rem;
    }
}

.delivery-request {
    border-top: 1px solid #d8d8d8;
}

.delivery-request__table-wrap {
    margin-top: 5.5rem;
}

@media all and (max-width: 575px) {
    .delivery-request__table-wrap {
        transition: 0.5s;
        margin-top: 0;
    }
}

.delivery-request__table-wrap__title {
    font-size: 1.125rem;
    color: #242a18;
    font-weight: 600;
}

.delivery-request__table-wrap .table {
    min-width: 400px;
}

.delivery-request__table-wrap .table tr th {
    font-weight: normal;
    color: #5d6154;
    border: none;
    padding: 1.5rem 0.75rem 0;
}

.delivery-request__table-wrap .table tr th:last-child {
    padding-right: 0;
}

.delivery-request__table-wrap .table tr th:first-child {
    padding-left: 0;
}

.delivery-request__table-wrap .table tr th:first-child, .delivery-request__table-wrap .table tr th:nth-child(2) {
    min-width: 98px;
}

.delivery-request-products {
    margin: 4.5rem 0 0 0;
    padding: 0;
    list-style: none;
}

@media all and (max-width: 575px) {
    .delivery-request-products {
        transition: 0.5s;
        margin: 1.5rem 0 0 0;
    }
}

.product-oqt {
    color: #242a18;
}

.product-oqt__tracking {
    color: #88b34c;
}

.product-oqt__tracking a {
    color: #88b34c;
    transition: 300ms ease-in-out color;
    text-decoration: underline;
}

.product-oqt__tracking a:hover {
    color: #fe9801;
}
