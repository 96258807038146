.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1d3148;
  border-color: #1d3148;
}

.edit-import-top-text {
  color: #111827;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.edit-import-top-cta-wrapper {
  display: flex;
  gap: 16px;
  justify-content: end;
}

@media (max-width: 440px) {
  .edit-import-top-cta-wrapper {
    display: flex;
    justify-content: start;
    gap: 4px;
    flex-wrap: wrap;
  }
}

.edit-import-top-cta1 {
  background: #ddd;
  color: #4d4d4d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 18px;
}

.edit-import-top-cta2 {
  background: #1d3148;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 33px;
}

.edit-import-top-cta3 {
  background: #f37b78;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.edit-import-status-text {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.edit-import-status-link {
  color: #1d9bf0;
}

.edit-import-info-header {
  color: #111827;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.edit-import-info-text {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.edit-import-info-text-store {
  background: rgba(255, 127, 118, 0.1);
  padding: 8px 32px;
  border-radius: 50px;
  border: 1px solid #ff7f76;
  color: #955ff0;
  font-size: 14px;
  color: #ff7f76;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit-import-info-label {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.edit-import-info-link {
  color: #1d9bf0;
}

.edit-import-variant-text {
  color: #111827;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}
.edit-import-shipping-table {
  width: 650px;
}

@media (max-width: 550px) {
  .edit-import-shipping-table {
    overflow-x: auto;
  }
}

.edit-import-shipping-table-cell-text {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-import-table-cell-text {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.edit-import-image-upload-text {
  font-family: Inter;
  font-size: 18px;
}

.edit-import-images-text {
  color: #111827;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.edit-import-images-select-text {
  color: #111827;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.edit-import-images-editor-text {
  color: #1d9bf0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.edit-import-tooltip-text1 {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit-import-tooltip-text2 {
  color: #9aa1ad;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-import-tooltip-checkbox-text {
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-import-tooltip-list-text {
  color: #121212;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit-import-tooltip-btn {
  border-radius: 4px;
  background: #f37b78;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-import-tooltip-btn-text {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit-import-variant-box-text1 {
  color: #121212;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 12px;
}

.edit-import-variant-box-text2 {
  color: #4d4d4d;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 16px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #04af7b;
  background-color: #04af7b;
}

.edit-import-price-increase-btn {
  padding-top: 4px;
  width: 130px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  color: #121212;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.edit-import-gallery-select-wrapper {
  margin-left: 27px;
}

@media (max-width: 428px) {
  .edit-import-gallery-select-wrapper {
    width: 100%;
    margin-top: 8px;
    margin-left: 0px;
  }
}
