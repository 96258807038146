.product-progress {
    max-width: 750px;
    margin: 0 auto;
}

/* Border Color */
/* Transition */
.progress-wrap {
    padding: 0;
    list-style: none;
    margin: 1.5625rem 0.9375rem 2.5rem;
    text-align: center !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row !important;
}

@media all and (max-width: 575px) {
    .progress-wrap {
        transition: 0.5s;
        margin-bottom: 1rem;
    }
}

.progress-wrap__item input[type=checkbox] {
    display: none;
}

.progress-wrap__item input[type=checkbox]:checked ~ label {
    color: #88b34c;
}

.progress-wrap__item input[type=checkbox]:checked ~ label::before {
    background: #88b34c;
}

.progress-wrap__item input[type=checkbox]:checked ~ label::after {
    background: #88b34c;
    content: '\2713';
    line-height: 1.375rem;
    color: #fff;
    border-color: #88b34c;
    text-align: center;
}

.progress-wrap__item label {
    position: relative;
    cursor: pointer;
    padding-bottom: 2.25rem;
    font-weight: 600;
    color: #242a18;
}

.progress-wrap__item label:before {
    content: "";
    position: absolute;
    top: 3rem;
    right: 50%;
    left: 0;
    height: 0.125rem;
    background-color: #fe9801;
}

.progress-wrap__item label:after {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid #fe9801;
    border-radius: 50%;
    top: 2.25rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    background: #fff;
}

.progress-wrap__item label input[type=checkbox]:checked ~ label {
    color: #88b34c;
}

.progress-wrap__item:first-child label:before {
    z-index: 5;
    left: 0;
    background-color: #fff !important;
}

.progress-wrap__item:nth-child(2) label:before {
    z-index: 4;
    left: -100px;
}

.progress-wrap__item:nth-child(3) label:before {
    z-index: 3;
    left: -205px;
}

.progress-wrap__item:nth-child(4) label:before {
    z-index: 2;
    left: -205px;
}

.progress-wrap__item:last-child label:before {
    z-index: 1;
    left: -205px;
}

@media all and (max-width: 991px) {
    .progress-wrap__item label {
        margin-left: 30px;
    }

    .progress-wrap__item label:after {
        top: 0;
        left: -15px;
        transform: translateX(-30px);
    }

    .progress-wrap__item label:before {
        top: 0;
        bottom: -25px;
        right: 0 !important;
        left: -34px !important;
        width: 2px;
        height: auto !important;
        background-color: #fe9801;
    }

    .progress-wrap__item:first-child label:before {
        background-color: #fe9801 !important;
    }

    .progress-wrap__item:last-child label:before {
        bottom: unset;
    }
}
 